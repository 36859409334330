// Lazy loading images when they are 400 below page fold and they will fade in
$(function() {
  return $(".carousel.lazy").on("slide.bs.carousel", function(ev) {
    var lazy;
    lazy = $(ev.relatedTarget).find("img[data-src]");
    lazy.attr("src", lazy.data('src'));
    lazy.removeAttr("data-src");
  });
});

//Add children age
$(".children").keyup(function(){
  var numberOfChildren = $("#children").val();
  var maxPax = $('.max-pax').attr('max');
  $( ".children-age-each" ).empty();
  if (numberOfChildren > 0){
    var range = _.range(numberOfChildren);
    var html1 = "<div class='col-lg-12 margin-top-20'><label for='Age_of_Children:'>*Age of child "
    var html2 = ":</label></div><div class='col-lg-12 no-side-padding children-age-each'><input type='number' name='children-age-each-input' class='children-age-each-input form-control' min='0' max='18' required='true'></div></div>"
    $.each(range, function( index, value ) {
      $( ".children-age-each" ).first().append( html1 + (index + 1) + html2 );
    });
  }
})
$('.children-age-each').change(function(){
  var childrenArray = new Array();
  $('.children-age-each-input').each(function(){
    childrenArray.push($(this).val());
  });
  var childrenAgeString = childrenArray.toString();
  $("#children_age_string").val(childrenAgeString);
});

document.addEventListener("DOMContentLoaded", function(){
  // add flash messages back in
  $("#flash-modal-success").modal('show');
  $("#flash-modal-warning").modal('show');
  
  // Lazy load images
  $("img").lazyload({
    threshold : 1200,
    effect : "fadeIn"
  });
  
  //restaurant selectors so that No Restaurant can be selected but validation persists
  $( ".restaurant-selector" ).change(function() {
    thissy = $(this)
    id = $(this)[0].id
    console.log(selectedOption = "")
    console.log(selectedOption = $("select#" + id + "_restaurant option:selected" ).text())
    if (selectedOption == "Please don't book this day for us") {
      $(".restaurants-form").nextAll().each(function(index, element){
        console.log($("."+id+"_selects")[0].required = false)
        console.log($("."+id+"_selects")[1].required = false)
        console.log($("."+id+"_selects")[2].required = false)
        console.log($("."+id+"_selects")[0].disabled = true)
        console.log($("."+id+"_selects")[1].disabled = true)
        console.log($("."+id+"_selects")[2].disabled = true)
      });
    } else {
      $(".restaurants-form").nextAll().each(function(index, element){
        console.log($("."+id+"_selects")[0].required = true)
        console.log($("."+id+"_selects")[1].required = true)
        console.log($("."+id+"_selects")[2].required = true)
        console.log($("."+id+"_selects")[0].disabled = false)
        console.log($("."+id+"_selects")[1].disabled = false)
        console.log($("."+id+"_selects")[2].disabled = false)
      });
    }
  });
});
document.addEventListener("DOMContentLoaded", function(){
  // Timeout for modal
  setTimeout(function(){
    if (document.cookie.split(';').filter(function(item) {
        return item.trim().indexOf('modalSeen=') == 0
    }).length){
        console.log('The cookie "modalSeen" exists (ES5)')
    }else{
      $('#subscribe-modal').modal('show');
      document.cookie = "modalSeen=true";
    }
  }, 8000);
  $('#flashModal').modal('show');
});
// Adds checkbox styling from quill editor
$("ul[data-checked='false'] > :even").prepend('<input type="checkbox" name="myCheckbox" /> ').addClass('checkbox-quill-editor background-gray');
$("ul[data-checked='false'] > :odd").prepend('<input type="checkbox" name="myCheckbox" /> ').addClass('checkbox-quill-editor');